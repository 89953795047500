<!-- begin #header -->
<div id="header" class="header navbar-default"
     style="background: #ED1C24!important;font-size: 16px;color: white!important;">
  <!-- begin navbar-header -->
  <div class="navbar-header">

    <div id="_dt">
      <i class="far fa-lg fa-fw m-r-10 fa-calendar-alt"></i>{{date}}
      |


      <ul class="navbar-nav" style="display: inline-flex">
        <li class="dropdown" ngbDropdown style="margin: 0; padding: 0; border: 0;">
          <a href="javascript:;" class="dropdown-toggle" [ngClass]="active_admin_class" ngbDropdownToggle>
            <i class="fa fa-user"></i>
            {{(user_info) ? user_info['fname'] : ''}}
            <b class="caret ml-2 ml-md-0"></b>
          </a>
          <div class="dropdown-menu" ngbDropdownMenu>
            <a (click)="openChangPass(modalPassword)" style="cursor: pointer;">
              <i class="fas fa-lg fa-fw fa-key mr-2"></i>
              Change password
            </a>
          </div>
        </li>
      </ul>

      | <i class="far fa-lg fa-fw fa-registered"></i>
      {{(user_info) ? user_info['role']['role_name'] : ''}}
    </div>
    <!-- example 1 - using absolute position for center -->
    <nav class="navbar navbar-expand-md navbar-dark">
      <a href="/#/dashboard" class="mr-2">
        <img [src]="logo" [ngClass]="logo_size">
      </a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsingNavbar"
              (click)="toggleMenu('besics')">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse" id="collapsingNavbar" *ngIf="dropdowns['besics']">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" *ngxPermissionsOnly="['superadmin', 'supervisor_manager','operator']">
            <a class="nav-link" routerLink="/dashboard" routerLinkActive="active" (click)="toggleMenu('besics')">
              <i class="fa fa-home"></i>
              Dashboard
              <span class="sr-only">(current)</span>
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['superadmin', 'supervisor_manager','operator']">
            <a class="nav-link" routerLink="/work-order" routerLinkActive="active" (click)="toggleMenu('besics')">
              <i class="far fa-file-alt"></i>
              Work Order
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['superadmin', 'supervisor_manager','operator']">
            <a class="nav-link" routerLink="/alarm" routerLinkActive="active" (click)="toggleMenu('besics')">
              <i class="fa fa-bell"></i>
              Alarm
            </a>
          </li>
<!--          <li class="nav-item" *ngxPermissionsOnly="['superadmin', 'supervisor_manager','operator']">-->
<!--            <a class="nav-link" (click)="toggleMenu('besics')">-->
<!--          <i class="fas fa-tools"></i>-->
<!--          App PM-->
<!--        </a>-->
<!--          </li>-->
          <li class="dropdown" ngbDropdown *ngxPermissionsOnly="['superadmin', 'supervisor_manager','operator']">
            <a href="javascript:;" class="dropdown-toggle" ngbDropdownToggle>
              <i class="fa fa-cog"></i>
              <span class="mr-2">Setting</span>
              <b class="caret ml-2 ml-md-0"></b>
            </a>
            <div class="dropdown-menu" ngbDropdownMenu>
<!--              <a class="dropdown-item" style="color: black" (click)="toggleMenu('besics')">-->
<!--                <i class="fas fa-lg fa-fw mr-2 fa-address-card"></i> Profile-->
<!--              </a>-->
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/user-management/users" routerLinkActive="active" (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw fa-users mr-3"></i>
                User Management
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/machine" routerLinkActive="active" (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Machine
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/device" routerLinkActive="active" (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Device
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/pole-type" routerLinkActive="active" (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Pole Type
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/work-type" routerLinkActive="active" (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Work Type
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/order-state" routerLinkActive="active" (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Order State
              </a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" style="color: black" routerLink="/check-list" routerLinkActive="active" (click)="toggleMenu('besics')">
                <i class="fas fa-lg fa-fw mr-2 fa-list-alt"></i>
                Check List
              </a>
            </div>
          </li>
          <li class="nav-item" *ngxPermissionsOnly="['superadmin', 'supervisor_manager','operator']">
            <a class="nav-linK" routerLink="/login" (click)="logOut()">
              <i class="fas fa-lock"></i> Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>

  </div>
</div>

<ng-template #modalPassword let-c="close" let-d="dismiss">
  <form [formGroup]="loginForm" (ngSubmit)="changPassword()">
    <div class="modal-header">
      <h4 class="modal-title">Change password user : {{ user_item.fname }} {{ user_item.lname }}</h4>
      <i class="bx bx-x fa-lg" (click)="c('Close click')"></i>
    </div>
    <div class="modal-body row f-s-16">

      <div class="form-group col-sm-12">
        <label class="col-sm-12 col-form-label">New Password</label>
        <div class="col-sm-12 input-group">
          <input [type]="repeatFieldTextType ? 'text' : 'password'" class="form-control" formControlName="input_password"
                 [ngClass]="{'is-invalid': form.input_password.errors}">
          <div class="input-group-append">
          <span class="input-group-text">
           <i class="fa" [ngClass]="{ 'fa-eye-slash': !fieldTextType, 'fa-eye': fieldTextType }"
              (click)="toggleRepeatFieldTextType()"></i>
          </span>
          </div>
          <div *ngIf="form.input_password.errors" class="invalid-feedback">
            <span>The password must be at least 8 characters and contain at least one uppercase character.</span>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-light" (click)="c('Close click')">cancel</button>
      <button type="submit" class="btn btn-success">Save</button>
    </div>
  </form>
</ng-template>
