// tslint:disable-next-line:class-name
export class chartOption {
  lineDailyOption() {
    return {
      series: [
        {
          name: '',
          data: [0]
        }
      ],
      chart: {
        width: '100%',
        height: 280,
        type: 'line',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      title: {
        text: '',
        align: 'left'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.5
        }
      },
      xaxis: {
        categories: ['0']
      }
    };
  }
}
