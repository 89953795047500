import {Component, OnDestroy} from '@angular/core';
import pageSettings from '../../config/page-settings';

@Component({
  selector: 'error-404',
  templateUrl: './error-404.html'
})

export class Error404Page implements OnDestroy {
  pageSettings = pageSettings;

  constructor() {
    this.pageSettings.pageEmpty = true;
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }
}
