import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CommonModule} from '@angular/common';
import {AuthGuard} from './auth/auth.guard';
// Home
import {LoginComponent} from './login/login.component';
import {Error404Page} from './pages/error-404/error-404';

const routes: Routes = [
  {path: '', redirectTo: '/login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, data: {title: 'Login'}},
  {
    path: 'user-management',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'users',
        loadChildren: () => import('./pages/user-management/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'roles',
        loadChildren: () => import('./pages/user-management/roles/roles.module').then(m => m.RolesModule)
      },
      {
        path: 'department',
        loadChildren: () => import('./pages/user-management/department/department.module').then(m => m.DepartmentModule)
      },
      {
        path: 'position',
        loadChildren: () => import('./pages/user-management/position/position.module').then(m => m.PositionModule)
      },
    ]
  },
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
    ]
  }, {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'work-order',
        loadChildren: () => import('./pages/work-order/work-order.module').then(m => m.WorkOrderModule)
      },
    ]
  }, {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'operation/:work_order/:mode',
        loadChildren: () => import('./pages/operation/operation.module').then(m => m.OperationModule)
      },
    ]
  }, {
    path: '',
    children: [
      {
        path: 'alarm',
        loadChildren: () => import('./pages/alarm/alarm.module').then(m => m.AlarmModule)
      },
    ]
  }, {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'report1/:id',
        loadChildren: () => import('./pages/report1/report1.module').then(m => m.Report1Module)
      },
    ]
  }, {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'report2/:id',
        loadChildren: () => import('./pages/report2/report2.module').then(m => m.Report2Module)
      },
    ]
  }, {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'machine',
        loadChildren: () => import('./pages/machine/machine.module').then(m => m.MachineModule)
      },
    ]
  }, {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'device',
        loadChildren: () => import('./pages/device/device.module').then(m => m.DeviceModule)
      },
    ]
  }, {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'pole-type',
        loadChildren: () => import('./pages/pole-type/pole-type.module').then(m => m.PoleTypeModule)
      },
    ]
  }, {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'work-type',
        loadChildren: () => import('./pages/work-type/work-type.module').then(m => m.WorkTypeModule)
      },
    ]
  }, {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'order-state',
        loadChildren: () => import('./pages/order-state/order-state.module').then(m => m.OrderStateModule)
      },
    ]
  }, {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'check-list',
        loadChildren: () => import('./pages/check-list/check-list.module').then(m => m.CheckListModule)
      },
    ]
  },
  {path: '404', component: Error404Page},
  {path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule],
  declarations: []
})


export class AppRoutingModule {
}
