// Core Module
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule, Title } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import * as global from "./config/globals";

// Main Component
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { SidebarRightComponent } from "./components/sidebar-right/sidebar-right.component";
import { TopMenuComponent } from "./components/top-menu/top-menu.component";
import { FooterComponent } from "./components/footer/footer.component";
import { PanelComponent } from "./components/panel/panel.component";
import { FloatSubMenuComponent } from "./components/float-sub-menu/float-sub-menu.component";

// Component Module
// import {AgmCoreModule} from '@agm/core';

import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { TrendModule } from "ngx-trend";
import { HighlightJsModule } from "ngx-highlight-js";
import { TagInputModule } from "ngx-chips";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { PERFECT_SCROLLBAR_CONFIG } from "ngx-perfect-scrollbar";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";

// Auth
import { AuthGuard } from "./auth/auth.guard";
import { AuthService } from "./auth/authservice";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
};

// @ts-ignore
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { ErrorIntercept } from "./provider/error.interceptor";
import { ServicesProvider } from "./provider/services";

import { LoginComponent } from "./login/login.component";
import { Error404Page } from "./pages/error-404/error-404";
import { chartOption } from "./config/apex-charts";
import { NgxPermissionsModule } from "ngx-permissions";
import { BlockUIModule } from "ng-block-ui";
import { MqttModule, IMqttServiceOptions } from "ngx-mqtt";
import { environment } from "../environments/environment";

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqttServer,
  port: environment.mqttPort,
  protocol: environment.mqttProtocol,
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    SidebarRightComponent,
    TopMenuComponent,
    FooterComponent,
    PanelComponent,
    FloatSubMenuComponent,
    LoginComponent,
    Error404Page,
  ],
  imports: [
    NgxPermissionsModule.forRoot(),
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HighlightJsModule,
    LoadingBarRouterModule,
    NgbModule,
    NgxDaterangepickerMd.forRoot(),
    PerfectScrollbarModule,
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    TagInputModule,
    TrendModule,
    BlockUIModule.forRoot(),
  ],
  providers: [
    Title,
    ServicesProvider,
    chartOption,
    AuthGuard,
    AuthService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorIntercept, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(
    private router: Router,
    private titleService: Title,
    private route: ActivatedRoute
  ) {
    router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        let title = "SCG";
        this.titleService.setTitle(title);
      }
    });
  }
}
