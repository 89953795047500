import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import pageSettings from '../config/page-settings';
import {ServicesProvider} from '../provider/services';
import {UntypedFormGroup, Validators, UntypedFormBuilder} from '@angular/forms';
import * as moment from 'moment';
import Swal, {SweetAlertOptions} from 'sweetalert2';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  pageSettings = pageSettings;
  alertLogin = false;
  public loginForm: UntypedFormGroup;
  year = moment().format('YYYY');
  pinCode = [];
  position: number;
  user_email_store = '';
  pin_code_tore = '';
  @ViewChild('first') first: ElementRef;
  @ViewChild('second') second: ElementRef;
  @ViewChild('third') third: ElementRef;
  @ViewChild('fourth') fourth: ElementRef;
  @ViewChild('five') five: ElementRef;
  @ViewChild('six') six: ElementRef;

  constructor(private router: Router, private _services: ServicesProvider, private formBuilder: UntypedFormBuilder) {

    this.pageSettings.pageEmpty = true;
    this.loginForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if (localStorage.getItem('token')) {
      this.router.navigate(['/dashboard']);
    }
    this.position = 1;
  }

  ngOnDestroy() {
    this.pageSettings.pageEmpty = false;
  }

  formSubmit() {
    if (this.loginForm.valid) {
      const body = this.loginForm.value;
      this._services.loginByEmail(body).subscribe(res => {
        Swal.fire({
          icon: 'success',
          title: 'Your work has been saved',
          showConfirmButton: false,
          timer: 1000
        }).then(() => {
          localStorage.setItem('token', res.token);
          localStorage.setItem('user', JSON.stringify(res.user));
          localStorage.setItem('user_email', res.user.email);
          localStorage.setItem('permission', JSON.stringify(res.permissions));
          this.router.navigate(['/dashboard']);
        });
      }, (err) => {
        // tslint:disable-next-line:triple-equals
        if (err.status == 400) {
          Swal.fire('Error', 'ขออภัย ท่านระบุ Email และ Password ไม่ถูกต้อง', 'error');
        } else {
          console.log(err);
        }
      });
    }
  }

  enter(n: number) {
    this.pinCode.push(n);
    if (this.position < 7) {
      if (this.position === 1) {
        this.first.nativeElement.value = n;
      }
      if (this.position === 2) {
        this.second.nativeElement.value = n;
      }
      if (this.position === 3) {
        this.third.nativeElement.value = n;
      }
      if (this.position === 4) {
        this.fourth.nativeElement.value = n;
      }
      if (this.position === 5) {
        this.five.nativeElement.value = n;
      }
      if (this.position === 6) {
        this.six.nativeElement.value = n;
      }
      this.position++;

      if (this.position === 7) {
        const pinCodeRaw = Number(this.pinCode.join(''));
        const pinCode = pinCodeRaw.toString();
        if (!localStorage.getItem('pin_code')) {
          localStorage.setItem('pin_code', pinCode);
          this.router.navigate(['dashboard']);
        } else {
          if (pinCode == localStorage.getItem('pin_code')) {
            const user_email = localStorage.getItem('user_email');
            this._services.loginByPinCode({email: user_email}).subscribe(res => {
              Swal.fire({
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1000
              }).then(() => {
                localStorage.setItem('token', res.token);
                localStorage.setItem('user', JSON.stringify(res.user));
                this.router.navigate(['dashboard']);
              });
            }, (err) => {
              if (err.status === 400) {
                Swal.fire('Error', 'ขออภัย ท่านระบุ PIN ไม่ถูกต้อง', 'error');
              } else {
                console.log(err);
              }
            });
          } else {
            Swal.fire('Error', 'ขออภัย ท่านระบุ PIN ไม่ถูกต้อง', 'error');
            this.position = 1;
            this.first.nativeElement.value = '';
            this.second.nativeElement.value = '';
            this.third.nativeElement.value = '';
            this.fourth.nativeElement.value = '';
            this.five.nativeElement.value = '';
            this.six.nativeElement.value = '';
            this.pinCode = [];
          }

        }
      }
    }
  }

  back() {
    if (this.position > 1) {
      this.position--;
      if (this.position === 1) {
        this.first.nativeElement.value = '';
      }
      if (this.position === 2) {
        this.second.nativeElement.value = '';
      }
      if (this.position === 3) {
        this.third.nativeElement.value = '';
      }
      if (this.position === 4) {
        this.fourth.nativeElement.value = '';
      }
      if (this.position === 5) {
        this.five.nativeElement.value = '';
      }
      if (this.position === 6) {
        this.six.nativeElement.value = '';
      }
      if (this.position <= 0) {
        this.pinCode = [];
      }
      this.pinCode.splice(this.position - 1, 1);
      // console.log(this.pinCode);
      /*console.log(
        'position is ' +
        this.position +
        ' ' +
        'PIN is ' +
        this.first.nativeElement.value +
        this.second.nativeElement.value +
        this.third.nativeElement.value +
        this.fourth.nativeElement.value
      );*/
    }
  }

}
