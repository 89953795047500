import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";
import { HttpClient } from "@angular/common/http";
import { forkJoin, Observable } from "rxjs";
import { environment } from "../../environments/environment";

// import * as moment from 'moment';

@Injectable({
  providedIn: "root",
})
export class ServicesProvider {
  // api_url = 'http://192.168.1.99:9000/api/v1'; // prods
  // api_url = "http://localhost:3000/api"; // local
  // pdf_url = 'http://localhost:8000/api'; // local
  pdf_url = environment.pdfUrl; // prod
  api_url = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  loginByEmail(body): Observable<any> {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    return this.httpClient.post(this.api_url + "/login-by-email", body, {
      headers,
    });
  }

  loginByPinCode(body): Observable<any> {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    return this.httpClient.post(this.api_url + "/login-by-pin-code", body, {
      headers,
    });
  }

  logout(): Observable<any> {
    const body = {};
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/logout", body, { headers });
  }

  public getPosition() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get<any>(`${this.api_url}/position`, { headers });
  }

  public postPosition(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post<any>(`${this.api_url}/position`, body, {
      headers,
    });
  }

  public postPositionById(body, id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post<any>(`${this.api_url}/position/` + id, body, {
      headers,
    });
  }

  public deletePositionById(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.delete<any>(`${this.api_url}/position/` + id, {
      headers,
    });
  }

  public getDepartment() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get<any>(`${this.api_url}/department`, { headers });
  }

  public listGPS(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get<any>(`${this.api_url}/GPS/` + id, { headers });
  }

  public departmentListDetail() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get<any>(`${this.api_url}/department-list`, {
      headers,
    });
  }

  public departmentListDetailById(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get<any>(`${this.api_url}/department/` + id, {
      headers,
    });
  }

  public postDepartmentUpdate(body, id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post<any>(`${this.api_url}/department/` + id, body, {
      headers,
    });
  }

  public postDepartment(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post<any>(`${this.api_url}/department`, body, {
      headers,
    });
  }

  public deleteDepartment(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.delete<any>(`${this.api_url}/department/` + id, {
      headers,
    });
  }

  public getUsers() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get<any>(`${this.api_url}/users`, { headers });
  }

  public getUsersByID(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get<any>(`${this.api_url}/users/` + id, { headers });
  }

  public usersChangPassword(body, id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(
      `${this.api_url}/users-chang-pass/` + id,
      body,
      { headers }
    );
  }

  public createUser(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(`${this.api_url}/users`, body, { headers });
  }

  public usersUpdate(body, id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };

    return this.httpClient.post(`${this.api_url}/users/` + id, body, {
      headers,
    });
  }

  public deleteUser(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.delete(`${this.api_url}/users/` + id, { headers });
  }

  getRoles() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };

    return this.httpClient.get<any>(`${this.api_url}/roles`, { headers });
  }

  createRoles(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(`${this.api_url}/roles`, body, { headers });
  }

  editRoles(body, id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.patch(`${this.api_url}/roles/` + id, body, {
      headers,
    });
  }

  deleteRoles(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };

    return this.httpClient.delete(`${this.api_url}/roles/` + id, { headers });
  }

  getRoleHasPermission(role_id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(
      `${this.api_url}/role-has-permission/` + role_id,
      { headers }
    );
  }

  getPermission() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(`${this.api_url}/permissions`, { headers });
  }

  listDevice(select) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/devices/" + select, {
      headers,
    });
  }

  dashboardGet(table_name_r, _type) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(
      this.api_url + "/dashboard/" + table_name_r + "/" + _type,
      { headers }
    );
  }

  listMachine() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/machine", { headers });
  }

  ByIdMachine(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/machine/" + body, { headers });
  }

  createMachineCode(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/machine", body, { headers });
  }

  editMachineCode(body, id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.patch(this.api_url + "/machine/" + id, body, {
      headers,
    });
  }

  listPoleType() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/pole-type", { headers });
  }

  addPoleType(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/pole-type", body, { headers });
  }

  ByIdPoleType(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/pole-type/" + body, {
      headers,
    });
  }

  updatePoleType(id, body): Observable<any> {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/pole-type/" + id, body, {
      headers,
    });
  }

  deletePoleType(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.delete(this.api_url + "/pole-type/" + id, {
      headers,
    });
  }

  listWorkType() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/work-type", { headers });
  }

  addWorkType(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/work-type", body, { headers });
  }

  ByIdWorkType(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/work-type/" + body, {
      headers,
    });
  }

  updateWorkType(id, body): Observable<any> {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/work-type/" + id, body, {
      headers,
    });
  }

  deleteWorkType(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.delete(this.api_url + "/work-type/" + id, {
      headers,
    });
  }

  listOrderState() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/order-state", { headers });
  }

  addOrderState(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/order-state", body, {
      headers,
    });
  }

  ByIdOrderState(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/order-state/" + body, {
      headers,
    });
  }

  updateOrderState(id, body): Observable<any> {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/order-state/" + id, body, {
      headers,
    });
  }

  deleteOrderState(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.delete(this.api_url + "/order-state/" + id, {
      headers,
    });
  }

  listCheckList() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/check-list", { headers });
  }

  addCheckList(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/check-list", body, {
      headers,
    });
  }

  ByIdCheckList(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/check-list/" + body, {
      headers,
    });
  }

  updateCheckList(id, body): Observable<any> {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/check-list/" + id, body, {
      headers,
    });
  }

  deleteCheckList(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.delete(this.api_url + "/check-list/" + id, {
      headers,
    });
  }

  listWorkOrder(search,sort,type) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/work-order?search=" + search +"&sort=" + sort+"&type=" + type, {
      headers,
    });
  }

  StateEnd(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/work-order", body, {
      headers,
    });
  }

  AddWorkOrderList() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/add-work-order-list", {
      headers,
    });
  }

  addWorkOrder(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/add-work-order-list", body, {
      headers,
    });
  }

  addImgWorkOrder(body) {
    const headers = {
      // "Content-Type": "multipart/form-data;",
      // Accept: "*/*",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/add-work-order-img", body, {
      headers,
    });
  }

  updateStateOrder(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/updateState", body, {
      headers,
    });
  }

  WorkOrderByID(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/work-order/" + body, {
      headers,
    });
  }

  AddCheckList(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/checklist-result", body, {
      headers,
    });
  }

  SelectCheckListByID(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(
      this.api_url + "/select-checklist-result",
      body,
      { headers }
    );
  }

  AddPole(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/pole", body, { headers });
  }

  AddPoleType(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/pole-pole-type", body, {
      headers,
    });
  }

  WorkOrderAB(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/work-order-ab", body, {
      headers,
    });
  }

  loadReportDetail(id, version) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(
      this.api_url + "/report1/" + id + "/" + version,
      { headers }
    );
  }

  SaveRemark(id, version, body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(
      this.api_url + "/report1/" + id + "/" + version,
      body,
      { headers }
    );
  }

  loadReport2Detail(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/report2/" + id, { headers });
  }

  alarmEvent(dt) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/alarm-event/" + dt, {
      headers,
    });
  }

  alarmEventUpdate(id) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/alarm-event/update/" + id, {
      headers,
    });
  }

  devices() {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/device-all", { headers });
  }

  devicePost(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.post(this.api_url + "/devices", body, { headers });
  }

  devicePut(id, body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.put(this.api_url + "/devices/" + id, body, {
      headers,
    });
  }

  additionHoleDetail(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: localStorage.getItem("token"),
    };
    return this.httpClient.get(this.api_url + "/addition/detail/" + body, {
      headers,
    });
  }

  exportPdfA(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    return this.httpClient.post(this.pdf_url + "/export-pdf-a", body, {
      headers,
    });
  }

  exportPdfB(body) {
    const headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    return this.httpClient.post(this.pdf_url + "/export-pdf-b", body, {
      headers,
    });
  }
}
