<!-- begin login -->
<div id="bg-login" class="login login-with-news-feed">
    <div class="login-content">
      <div style="width: 100%; margin: auto; text-align: center">
      <img src="assets/img/scg-logo-red.png" class="scg-logo" width="360">
      </div>
      <!-- begin nav-tabs -->
      <ngb-tabset>
        <ngb-tab *ngIf="user_email_store">
          <ng-template ngbTabTitle>
            <span class="d-sm-none">Login with pin code</span>
            <span class="d-sm-block d-none">Login with pin code</span>
          </ng-template>
          <ng-template ngbTabContent>
            <div class="d-flex">
              TYPE PIN:
              <div>
                <table>
                  <tr>
                    <td><input class="input-pin-code" type="password" #first maxlength="1" required readonly placeholder="_" size="1"></td>
                    <td><input class="input-pin-code" type="password" #second maxlength="1" required readonly placeholder="_" size="1"></td>
                    <td><input class="input-pin-code" type="password" #third maxlength="1" required readonly placeholder="_" size="1"></td>
                    <td><input class="input-pin-code" type="password" #fourth maxlength="1" required readonly placeholder="_" size="1"></td>
                    <td><input class="input-pin-code" type="password" #five maxlength="1" required readonly placeholder="_" size="1"></td>
                    <td><input class="input-pin-code" type="password" #six maxlength="1" required readonly placeholder="_" size="1"></td>
                  </tr>
                </table>
              </div>
              <div class="mt-4">
                <table>
                  <tr>
                    <td class="text-center" width="90">
                      <div class="mb-3" (click)="enter(1)"> 1 </div>
                    </td>
                    <td class="text-center" width="90">
                      <div class="mb-3" (click)="enter(2)"> 2 </div>
                    </td>
                    <td class="text-center" width="90">
                      <div class="mb-3" (click)="enter(3)"> 3 </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <div class="mb-3" (click)="enter(4)"> 4 </div>
                    </td>
                    <td class="text-center">
                      <div class="mb-3" (click)="enter(5)"> 5 </div>
                    </td>
                    <td class="text-center">
                      <div class="mb-3" (click)="enter(6)"> 6 </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center">
                      <div class="mb-3" (click)="enter(7)"> 7 </div>
                    </td>
                    <td class="text-center">
                      <div class="mb-3" (click)="enter(8)"> 8 </div>
                    </td>
                    <td class="text-center">
                      <div class="mb-3" (click)="enter(9)"> 9 </div>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td class="text-center">
                      <div (click)="enter(0)"> 0 </div>
                    </td>
                    <td>
                      <div (click)="back()">
                        <i class="far fa-lg fa-fw m-r-10 fa-window-close"></i>
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </ng-template>
        </ngb-tab>
        <ngb-tab *ngIf="!user_email_store">
          <ng-template ngbTabTitle>
            <span class="d-sm-none">Login with email</span>
            <span class="d-sm-block d-none">Login with email</span>
          </ng-template>
          <ng-template ngbTabContent>
            <form [formGroup]="loginForm" (ngSubmit)="formSubmit()" class="margin-bottom-0">
              <div class="form-group m-b-15">
                <label>Email</label>
                <input type="text" class="form-control form-control-lg" placeholder="Email" formControlName="email" required/>
              </div>
              <div class="form-group m-b-15">
                <label>Password</label>
                <input type="password" class="form-control form-control-lg" placeholder="PASSWORD" formControlName="password" required/>
              </div>
              <div class="checkbox checkbox-css m-b-30">
                <input type="checkbox" id="remember_me_checkbox" value="" />
                <label for="remember_me_checkbox">Remember Me</label>
              </div>
              <div class="login-buttons">
                <button type="submit" [disabled]="!loginForm.valid" class="btn btn-success btn-block btn-lg">Sign me in</button>
              </div>
            </form>
          </ng-template>
        </ngb-tab>
      </ngb-tabset>
      <!-- end nav-tabs -->

      <hr/>
      <p class="text-center text-grey-darker mb-0">
        &copy; All Right Reserved {{year}}
      </p>
    </div>
</div>
<!-- end login -->
