import {Component, Input, Output, EventEmitter, Renderer2, OnDestroy, HostListener, OnInit} from '@angular/core';
import pageSettings from '../../config/page-settings';
import {NavigationStart, Router} from '@angular/router';
import {AuthService} from '../../auth/authservice';
import {NgxPermissionsService} from 'ngx-permissions';
import * as moment from 'moment';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import 'rxjs/add/operator/takeWhile';
import 'rxjs/add/observable/timer';
import Swal from "sweetalert2";
import {ServicesProvider} from "../../../../../client/src/app/provider/services";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  date: any;
  dropdowns = {
    'besics': false,
    'images': false,
    'forms': false,
    'service': false,
  };
  logo = 'assets/img/scg-logo.png';
  logo_size = 'logo-lg';
  active_maintenance_class = '';
  active_admin_class = '';
  active_setting_class = '';
  alive = true;
  user_info: any;

  closeResult = '';
  modalReference: any;
  new_password = '';
  user_id_chang_pass = '';
  password_msg = '';
  user_item: any;
  fieldTextType: boolean;
  repeatFieldTextType: boolean;
  btn_save_pass = false;
  public loginForm: UntypedFormGroup;
  pageSettings = pageSettings;

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // console.log(event);
    this.dropdowns['besics'] = window.innerWidth > 736;
    if (window.innerWidth <= 798) {
      this.logo = 'assets/img/logo-sm.jpg';
      this.logo_size = 'logo-sm';
    }
  }

  constructor(private permissionsService: NgxPermissionsService,
              private renderer: Renderer2,
              private modalService: NgbModal,
              private formBuilder: UntypedFormBuilder,
              private router: Router,
              private _services: ServicesProvider,
              private  _authService: AuthService) {
    this.loginForm = this.formBuilder.group({
      input_password: ['', Validators.minLength(7)]
    });

    if (window.innerWidth > 736) {
      this.dropdowns['besics'] = true;
    }
    if (window.innerWidth <= 798) {
      this.logo = 'assets/img/logo-sm.jpg';
      this.logo_size = 'logo-sm';
    }
    this.date = moment().format('DD-MM-YYYY HH:mm:ss');
    if (localStorage.getItem('user')) {
      const users = JSON.parse(localStorage.getItem('user'));
      this.user_info = users['prefix'] + users['fname'] + ' ' + users['lname'];
    }
  }

  toggleMenu(name: string) {
    if (window.innerWidth < 736) {
      this.dropdowns[name] = !this.dropdowns[name];
    }
  }

  toggleRepeatFieldTextType() {
    this.repeatFieldTextType = !this.repeatFieldTextType;
    this.fieldTextType = !this.fieldTextType;
  }

  ngOnInit(): void {
    Observable.timer(0, 1000) // 72000-> 1.2 Minutes
      .takeWhile(() => this.alive) // only fires when component is alive
      .subscribe(() => {
        this.date = moment().format('DD-MM-YYYY HH:mm:ss');
      });
    if (localStorage.getItem('permission')) {
      const perm = JSON.parse(localStorage.getItem('permission'));
      this.permissionsService.loadPermissions(perm);
    }

    if (localStorage.getItem('user')) {
      this.user_info = JSON.parse(localStorage.getItem('user'));
    }
  }

  logOut() {
    this._authService.logout();
    this.router.navigate(['login']);
  }

  openChangPass(content) {
    this.btn_save_pass = false;
    this.user_info = JSON.parse(localStorage.getItem('user'));
    this.password_msg = '';
    this.new_password = '';
    this.user_item = this.user_info;
    this.user_id_chang_pass = this.user_info.user_id;
    this.modalReference = this.modalService.open(content, {size: 'md', backdrop: 'static', centered: true});
    this.modalReference.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  changPassword() {
    const body = {
      password: this.loginForm.get('input_password').value
    };
    this._services.usersChangPassword(body, this.user_id_chang_pass).subscribe((resp) => {
      Swal.fire({
        icon: 'success',
        title: 'Your chang password success.',
        showConfirmButton: false,
        timer: 1500
      });
      this.modalService.dismissAll();
      this.btn_save_pass = false;
      this._authService.logout();
    }, (err) => {
      console.log(err);
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  get form() {
    return this.loginForm.controls;
  }
}
